html,
body {
  height: 100%;
  font-display: auto;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Open Sans',
    sans-serif;
}

#__next {
  height: 100%;
}

.ant-row {
  width: 100%;
}

.omh-accent-text {
  color: var(--primary-color) !important;
}

nav {
  background-color: var(--nav-background-color);
}

nav,
nav .nav-wrapper i,
nav a.button-collapse,
nav a.button-collapse i {
  height: 50px;
  line-height: 50px;
}

.nav-menu,
.ant-layout-header {
  background: var(--nav-background-color) !important;
  color: var(--navbar-font-color) !important;
}

.header-menu-item {
  background: var(--nav-background-color) !important;
}

.header-menu-item a {
  color: var(--navbar-font-color) !important;
}

.header-menu-item:hover {
  color: var(--navbar-hover-font-color) !important;
}

.hamburger-menu {
  color: var(--navbar-font-color) !important;
}

.category-dropdown-button {
  color: var(--navbar-font-color) !important;
}

.category-dropdown-button i {
  color: var(--navbar-font-color) !important;
}

.dropdown-content li:hover {
  color: var(--navbar-hover-font-color);
  background-color: var(--primary-color);
}

.dropdown-content li > a:hover {
  color: var(--navbar-hover-font-color);
  background-color: var(--primary-color);
}

.nav-link-item:hover {
  color: var(--navbar-hover-font-color);
  background-color: transparent !important;
}

.nav-link-wrapper:hover {
  color: var(--navbar-hover-font-color);
  background-color: var(--primary-color);

  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.input-field label {
  font-size: 0.8rem !important;
}

.input-field input[type='search'] {
  padding-left: 32px;
  width: calc(100% - 32px);
  border-radius: 25px;
}

/*Card Carousel*/

.fade {
  position: relative;
  height: 6em; /* exactly 4 lines */
  line-height: 1.5em;
  overflow: hidden;
}

.fade span {
  height: 6em; /* exactly 4 lines */
  line-height: 1.5em;
}
.fade:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  height: 1.5em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

.attribute-collection-item {
  padding: 2px;
}

.attribute-collection-item p {
  margin: 0;
}

.page-footer {
  color: var(--footer-font-color);
}

.footer-copyright {
  color: var(--footer-font-color) !important;
  background-color: var(--footer-bg-color) !important;
}

.word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

.pattern-grid-md {
  background-image: linear-gradient(currentColor 1px, transparent 1px),
    linear-gradient(to right, currentColor 1px, transparent 1px);
  background-size: 25px 25px;
}

.pattern-dots-sm {
  background-image: radial-gradient(currentColor 0.5px, transparent 0.5px);
  background-size: calc(10 * 0.5px) calc(10 * 0.5px);
}
