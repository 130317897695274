@media print {
  nav {
    box-shadow: none !important;
  }

  .nav-link-wrapper {
    display: none !important;
  }

  .sidenav-trigger {
    display: none !important;
  }

  .embed-map-btn {
    display: none !important;
  }

  #user-map-button {
    display: none;
  }

  .mapboxgl-ctrl {
    display: none;
  }

  .z-depth-1,
  .z-depth-2,
  .z-depth-3,
  .z-depth-4 {
    box-shadow: none;
  }

  .SocialMediaShareButton {
    display: none;
  }
}
