.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}
.margin-auto {
  margin: auto auto;
}

.hidden {
  display: none;
}

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.collapsible-header {
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

/* Legacy responsive sizes originally from https://github.com/Dogfalo/materialize/blob/v1-dev/sass/components/_variables.scss */
/* Media Query Ranges */

:root {
  --small-screen-up: 601px;
  --medium-screen-up: 993px;
  --large-screen-up: 1201px;
  --small-screen: 600px;
  --medium-screen: 992px;
  --large-screen: 1200px;
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media only screen and (min-width: var(--small-screen-up)) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: var(--medium-screen-up)) {
  .container {
    width: 70%;
  }
}

.valign-wrapper {
  display: flex;
  align-items: center;
}

.center,
.center-align {
  text-align: center;
}

.page-footer {
  padding-top: 20px;
  color: var(--footer-font-color);
  background-color: var(--footer-bg-color);

  .footer-copyright {
    overflow: hidden;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    color: var(--footer-copyright-font-color);
    background-color: var(--footer-copyright-bg-color);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 110%;
}

/*********************
  Media Query Classes
**********************/

.hide-on-small-only,
.hide-on-small-and-down {
  @media only screen and (max-width: var(--small-screen)) {
    display: none !important;
  }
}
.hide-on-med-and-down {
  @media only screen and (max-width: var(--medium-screen)) {
    display: none !important;
  }
}
.hide-on-med-and-up {
  @media only screen and (min-width: var(--small-screen-up)) {
    display: none !important;
  }
}
.hide-on-med-only {
  @media only screen and (min-width: var(--small-screen)) and (max-width: var(--medium-screen)) {
    display: none !important;
  }
}
.hide-on-large-only {
  @media only screen and (min-width: var(--medium-screen-up)) {
    display: none !important;
  }
}
.hide-on-extra-large-only {
  @media only screen and (min-width: var(--large-screen-up)) {
    display: none !important;
  }
}
.show-on-extra-large {
  @media only screen and (min-width: var(--large-screen-up)) {
    display: block !important;
  }
}
.show-on-large {
  @media only screen and (min-width: var(--medium-screen-up)) {
    display: block !important;
  }
}
.show-on-medium {
  @media only screen and (min-width: var(--small-screen)) and (max-width: var(--medium-screen)) {
    display: block !important;
  }
}
.show-on-small {
  @media only screen and (max-width: var(--small-screen)) {
    display: block !important;
  }
}
.show-on-medium-and-up {
  @media only screen and (min-width: var(--small-screen-up)) {
    display: block !important;
  }
}
.show-on-medium-and-down {
  @media only screen and (max-width: var(--medium-screen)) {
    display: block !important;
  }
}
/* truncate text */
.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
