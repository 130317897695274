:root {
  --maphubs-accent-color: #3698ec;

  --primary-color: var(--maphubs-accent-color);
  --primary-color-light: lighten(var(--primary-color), 15%);
  --primary-color-dark: darken(var(--primary-color), 15%);

  --navbar-font-color: #323333;
  --navbar-hover-font-color: #fff;

  --link-color: var(--primary-color-light);

  --nav-background-color: white;
  --footer-bg-color: #fff;
  --footer-font-color: #323333;
  --header-font-color: #29abe2;
}
